<template>
  <section style="height: 100%">
    <v-container fluid>
    <v-row justify="start">
      <v-col cols="12" class="pa-5">
        <base-heading>{{$t('imprint.title')}}</base-heading>
        <base-text>
          <p>Gerhard Herrera</p>
          <p>
            <span>Neckarstraße 11</span><br>
            <span>50859 Köln</span>
          </p>
          <p>Ust.-Nr. </p>
          <p>{{$t('imprint.ust')}}</p>
          <p><a href="mailto:mail@gerhardherrera.com"> mail@gerhardherrera.de</a></p>
          <p>
            <span>Tel.: +492234497710</span><br>
            <span>Mobil: +491735716355</span><br>
            <span>Fax: +49223447652</span>
          </p>
        </base-text>
        <base-heading>{{$t('imprint.disclaimer')}}</base-heading>
        <base-text>{{$t('imprint.disclaimerText')}}</base-text>
        <base-heading>{{$t('imprint.liability')}}</base-heading>
        <base-text>{{$t('imprint.liabilityText')}}</base-text>
        <base-heading>{{$t('imprint.privacy')}}</base-heading>
        <base-text>{{$t('imprint.privacyText')}}</base-text>
      </v-col>
    </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
name: "Imprint"
}
</script>

<style scoped>

</style>
