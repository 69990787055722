import Vue from 'vue'
import Vuex from 'vuex'
import { set, toggle } from '@/utils/vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialog: false
  },
  mutations: {
    setDialog: set('dialog'),
    toggleDialog: toggle('dialog'),
  },
  actions: {
  },
  modules: {
  }
})
