import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
    de:{
        lng: {
            de: 'DE',
            en: 'EN',
            es: 'ES'
        },
        welcome:{
            title: 'Gerhard Herrera',
            subtitle_1: 'Dipl.Dolm. Dipl.Übers. (BDÜ)',
            subtitle_2:'Durch den Präsidenten des Oberlandesgerichts Köln ermächtigter Übersetzer\n' +
                'für die spanische und englische Sprache',
            text: 'Professionelle Übersetzungen für Spanisch/Deutsch/Spanisch, Englisch/Spanisch und Englisch/Deutsch.',
            text1: 'Spezialist für Eilaufträge',
            btn: 'Erfahren Sie mehr'
        },
        projects:{
            title: 'Fachgebiete',
            projects: [
                'Recht',
                'Medizin/\nPharmazie',
                'Technik',
                'Wirtschaft'
            ],
            text: 'Weitere Fachgebiete erörtere ich Ihnen gerne in einem persönlichen Gespräch.',
            btn: 'Kontakt aufnehmen'
        },
        services:{
            title: 'Lebenslauf',
            birth: {
                date: '01.03.1959',
                text: 'geboren in Montevideo, Uruguay'
            },
            school: {
                date: '1965 - 1973',
                text: 'Volksschule Augsburg-Spickel, Holbein-Gymnasium Augsburg'
            },
            school1: {
                date: '1974 - 1975',
                text: 'St. Dunstan\'s College Public School, London'
            },
            school2: {
                date: '1976 - 1978',
                text: 'Paul-Natorp-Oberschule Berlin (Abitur)',
            },
            school3: {
                date: '1979 - 1982',
                text: 'Studium der Anglistik, Romanistik und der Politikwissenschaften an der Universität zu Köln',
            },
            business: {
                date: '1982',
                text: 'Gründung eines eigenen Übersetzungsbüros in Köln'
            },
            school4: {
                date: '1982 - 1986',
                text: 'Studium an der FH Köln (1985 Dipl.Übers., 1986 Dipl.Dolm.)',
            },
            ktt: {
                date: '2001',
                text: 'Gründung des Kölner Triathlon-Team 01.',
                text1: 'Seitdem 1. Vorsitzender des KTT 01, das mit knapp 300 Mitgliedern zu den größten und mit vier Bundesliga-Mannschaften (1. und 2. Liga, D+H) zu den erfolgreichsten Triathlon-Vereinen Deutschlands zählt'
            },
        },
        about: {
            title: 'Über mich',
            member: 'Mitgliedschaft: Bundesverband der Dolmetscher und Übersetzer (BDÜ), FC Bayern München',
            hobby: ' Hobbies: Sport (10 Ironman, über 200 Triathlons, inzwischen Gravel-Biken), Lesen, Bücher (Die Andere Bibliothek), Mallorca, Wandern und alle anderen Aktivitäten mit unseren Tibet-Terriern, Tandemfahren …',
            motto: 'Motto:'
        },
        getInTouch: {
            title: 'Kontaktformular',
            text: 'Anfragen mit Angabe des Dokuments, des Umfangs und des Wunschtermins:',
            name: 'Name',
            email: 'E-Mail',
            subject: 'Betreff',
            message: 'Nachricht',
            btn: 'Senden',
            rules:{
                name: {
                    required: 'Das Feld Name ist Pflicht.',
                    less: 'Das Feld muss weniger als 20 Zeichen beinhalten.'
                },
                email: {
                    required: 'Das Feld E-Mail ist Pflicht.',
                    valid: 'Geben Sie eine valide Email-Adresse ein.'
                },
                subject: {
                    required: 'Das Feld Betreff ist Pflicht.',
                    less: 'Das Feld muss weniger als 20 Zeichen beinhalten.'
                },
                text: {
                    required: 'Das Feld Nachricht ist Pflicht.',
                    less: 'Das Feld muss weniger als 70 Zeichen beinhalten.'
                }

            }
        },
        footer: {
            Imprint: 'Home',
            Home: 'Impressum'
        },
        dialog: {
            Phone: 'Telefon',
            MobilePhone: 'Handy',
            Email: 'E-Mail',
            Fax: 'Fax',
            btn: 'Schließen'
        },
        imprint: {
            title:'Impressum',
            disclaimer: 'Disclaimer',
            disclaimerText: 'Aufgrund des Urteils des Landgerichts Hamburg Akz. 312 O 85/98 vom 12.05.1998, wonach durch die Anbringung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten ist, wird folgende Erklärung abgegeben:\n' +
                '\n' +
                'Hiermit distanziert sich Gerhard Herrera ausdrücklich von allen Inhalten aller von ihm gelinkten Seiten und macht sich deren Inhalte nicht zu eigen. Diese Erklärung gilt für alle auf den Seiten dieser Website angebrachten Links. Sollten sich irgendwo auf diesen Seiten Dinge finden, die urheberrechtlich geschützt sind, so bittet Gerhard Herrera darum, ihm diese umgehend anzuzeigen, damit er sie gegebenenfalls entfernen kann.',
            liability: 'Haftungsauschluss',
            ust: 'USt-ID. DE160847288',
            liabilityText: '',
            privacy: 'Datenschutz',
            privacyText: 'Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (eMail-Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis.'
        }
    },
    en:{
        lng: {
            de: 'DE',
            en: 'EN',
            es: 'ES'
        },
        welcome:{
            title: 'Gerhard Herrera',
            subtitle_1: 'Dipl.Dolm. Dipl.Übers. (BDÜ)',
            subtitle_2:'Translator authorized by the President of the Higher Regional Court of Cologne \n' +
                'for Spanish and English',
            text: 'Professional translations in the language combinations Spanish/German/Spanish, English/Spanish and English/German.',
            text1: 'Specialized in urgent translations',
            btn: 'More information'
        },
        projects:{
            title: 'Specialist fields',
            projects: [
                'Law',
                'Medicine/\nPharmacy',
                'Technology and Industry',
                'Economy'
            ],
            text: 'Other areas of expertise can be discussed personally.',
            btn: 'Contact'
        },
        services:{
            title: 'CV',
            birth: {
                date: '01/03/1959',
                text: 'born in Montevideo, Uruguay'
            },
            school: {
                date: '1965 - 1973',
                text: 'Primary School Volksschule Augsburg-Spickel, Secondary School Holbein-Gymnasium Augsburg'
            },
            school1: {
                date: '1974 - 1975',
                text: 'St. Dunstan\'s College Public School, London'
            },
            school2: {
                date: '1976 - 1978',
                text: 'Secondary school Paul-Natorp-Oberschule Berlin (A Levels)',
            },
            school3: {
                date: '1979 - 1982',
                text: 'Studies of Anglistics, Romance languages and literature and Policital Science at Cologne University',
            },
            business: {
                date: '1982',
                text: 'Establishment of my own translation agency in Cologne'
            },
            school4: {
                date: '1982 - 1986',
                text: 'Studies at University of Applied Sciences Fachhochschule Köln (degrees as translator and interpreter: Dipl.Übers. in 1985 and Dipl.Dolm. in 1986)',
            },
            ktt: {
                date: '2001',
                text: 'Foundation of the club Kölner Triathlon-Team 01.',
                text1: 'Since then President of KTT 01, a club approaching the mark of 300 members and one of the most successful triathlon teams of Germany (first AND second division teams, M+W)'
            },
        },
        about: {
            title: 'About me',
            member: 'Member of: Bundesverband der Dolmetscher und Übersetzer (BDÜ), FC Bayern München',
            hobby: 'Hobbies: sports (10 Ironman and more than 200 triathlon finishes, nowadays gravel biking), reading, books ("Die Andere Bibliothek"), Majorca, hiking and all other activities with our Tibetan Terriers, ride our tandem …',
            motto: 'Motto:'
        },
        getInTouch: {
            title: 'Contact form',
            text: 'Request stating the type of document, the volumen and any deadline:',
            name: 'Name',
            email: 'Email',
            subject: 'Subject',
            message: 'Message',
            btn: 'Send',
            rules:{
                name: {
                    required: 'The field Name is mandatory.',
                    less: 'This field must comprise less than 20 characters.'
                },
                email: {
                    required: 'The field Email is mandatory.',
                    valid: 'Please state a valid email addresse.'
                },
                subject: {
                    required: 'The field Subject is mandatory.',
                    less: 'This field must comprise less than 20 characters.'
                },
                text: {
                    required: 'The field Message ist mandatory.',
                    less: 'This field must comprise less than 70 characters.'
                }

            }
        },
        footer: {
            Imprint: 'Home',
            Home: 'Imprint'
        },
        dialog: {
            Phone: 'Telephone',
            MobilePhone: 'Mobile phone',
            Email: 'Email',
            Fax: 'Fax',
            btn: 'Close'
        },
        imprint: {
            title:'Imprint',
            disclaimer: 'Disclaimer',
            ust: 'VAT ID. DE160847288',
            disclaimerText: 'Pursuant to the ruling of the Hamburg District Court, file no. 312 O 85/98 dated 12/05/1998, stating that the insertion of a link implies accountability for the contents of any linked page, we declare:\n' +
                '\n' +
                ' Gerhard Herrera expressly dissociates himself from the contents of all linked web pages and does not appropriate their content. This statement applies to all links inserted on the pages of this website.  If on these pages there was any content protected by copyright, Gerhard Herrera asks to informe him immediately in ordert o eliminate the link concerned if necessary.',
            liability: 'Exemption from liability',
            liabilityText: '',
            privacy: 'Data protection',
            privacyText: 'Inasmuch as this website offers the possibility to enter personal or commercial data (email, names, addresses), the disclosure of these data on the part of the user happens exclusively on a voluntary basis.'
        }
    },
    es:{
        lng: {
            de: 'DE',
            en: 'EN',
            es: 'ES'
        },
        welcome:{
            title: 'Gerhard Herrera',
            subtitle_1: 'Dipl.Dolm. Dipl.Übers. (BDÜ)',
            subtitle_2:'Traductor jurado por el Presidente del Tribunal Regional Superior de Colonia \n' +
                'para los idiomas español e inglés',
            text: 'Traducciones profesionales de las siguientes combinaciones: español/alemán/español, inglés/español e inglés/alemán.',
            text1: 'Especializado en pedidos urgentes',
            btn: 'Más información'
        },
        projects:{
            title: 'Áreas especializadas',
            projects: [
                'Derecho',
                'Medicina/\nFarmacia',
                'Tecnología e industria',
                'Economía'
            ],
            text: 'En una conversación personal podemos hablar asimismo sobre otros campos.',
            btn: 'Establecer contacto'
        },
        services:{
            title: 'Currículum',
            birth: {
                date: '01/03/1959',
                text: 'nacido en Montevideo, Uruguay'
            },
            school: {
                date: '1965 - 1973',
                text: 'Escuela primaria Volksschule Augsburg-Spickel, I.E.S. Holbein-Gymnasium Augsburg'
            },
            school1: {
                date: '1974 - 1975',
                text: 'St. Dunstan\'s College Public School, Londres'
            },
            school2: {
                date: '1976 - 1978',
                text: 'I.E.S. Paul-Natorp-Oberschule Berlin (bachillerato)',
            },
            school3: {
                date: '1979 - 1982',
                text: 'Estudios de filología inglesa y románica y de ciencias políticas en la Universidad de Colonia',
            },
            business: {
                date: '1982',
                text: 'Fundación de una agencia propia de traducciones en Colonia'
            },
            school4: {
                date: '1982 - 1986',
                text: 'Estudios de traducción e interpretación en la Universidad de Ciencias Aplicadas de Colonia (1985 Dipl.Übers., 1986 Dipl.Dolm.)',
            },
            ktt: {
                date: '2001',
                text: 'Fundación de la asociación Kölner Triathlon-Team 01.',
                text1: 'Desde entonces 1er presidente de KTT 01, una asociación que se cuenta entre las más exitosas de Alemania con unos 300 socios y cuatro equipos de primera luga (mujeres y hombres)'
            },
        },
        about: {
            title: 'Sobre mí',
            member: 'Miembro de: Bundesverband der Dolmetscher und Übersetzer (BDÜ), FC Bayern München',
            hobby: 'Pasatiempos: deporte (10 triatlones Ironman, más de 200 triatlones en total, Ironman, über 200 Triathlons, mientras tanto aficionado al Gravel Biking), leer, biblioteca propia (Die Andere Bibliothek), Mallorca, senderismo y cualquier otra actividad con nuestros terriers tibetanos, andar en tandem …',
            motto: 'Motto:'
        },
        getInTouch: {
            title: 'Formulario de contacto',
            text: 'Solicitudes con indicación del documento, del volumen y del plazo de entrega deseado:',
            name: 'Nombre',
            email: 'E-mail',
            subject: 'Asunto',
            message: 'Noticia',
            btn: 'Enviar',
            rules:{
                name: {
                    required: 'El campo Nombre es obligatorio.',
                    less: 'El campo debe tener menos de 20 caracteres.'
                },
                email: {
                    required: 'El campo e-mail es obligatorio.',
                    valid: 'Indique una dirección e-mail válida.'
                },
                subject: {
                    required: 'El campo Asunto es obligatorio.',
                    less: 'El campo debe tener menos de 20 caracteres.'
                },
                text: {
                    required: 'El campo Mensaje es obligatorio.',
                    less: 'El campo debe tener menos de 70 caracteres.'
                }

            }
        },
        footer: {
            Imprint: 'Home',
            Home: 'Aviso legal'
        },
        dialog: {
            Phone: 'Teléfono',
            MobilePhone: 'Móvil',
            Email: 'E-mail',
            Fax: 'Fax',
            btn: 'Cerrar'
        },
        imprint: {
            title:'Aviso legal',
            ust: 'No. de ident. a efectos del IVA: DE160847288',
            disclaimer: 'Disclaimer',
            disclaimerText: 'A raíz de la sentencia del Tribunal Regional de Hamburgo, exped. o. 312 O 85/98 del 12/05/1998, conforme a la cual la inserción de un enlace entraña responsabilidad por los contenidos de la página enlazada, deseamos declarar lo que sigue:\n' +
                '\n' +
                'Por la presente, Gerhard Herrera se distancia expresamente  de cualquier contenido de las páginas enlazadas por él y no aprueba su contenido. Esta declaración rige para todos los enlaces insertados en estas páginas. Sie cualquier contenido de estas páginas estuviera protegido por derechos de autor, Gerhard Herrera ruega ser ser informado sin demora alguna sobre ello a fin de eliminar en caso dado el contenido en cuestión.',
            liability: 'Exclusión de responsabilidad',
            liabilityText: '',
            privacy: 'Protección de datos',
            privacyText: 'Si dentro de la oferta Internet existe la posibilidad de facilitar datos personales o comerciales (dirección e-mail, nombres, direcciones), estos datos serán comunicados por parte del usuario expresamente en forma voluntaria.'
        }
    }

}


const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages
})

export default i18n
