<template>
  <v-app>
    <core-app-bar />
    <core-view />
    <core-footer />
    <core-modal />
  </v-app>
</template>

<script>
  export default {
    name: 'App',

    components: {
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreView: () => import('@/components/core/View'),
      CoreModal: () => import('@/components/core/Modal'),
    },
  }
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 1px;
  background: white;
}

::-webkit-scrollbar-track {
  background: transparent;
}
</style>
