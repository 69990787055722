import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#758899',
        secondary: '#5b6b7b',
        accent: '#6b7785',
        info: '#414046',
      },
    },
  },
})
