<template>
  <p
    class="text-body-1 text-md-subtitle-1 font-weight-light"
    style="line-height: 1.7 !important;"
  >
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseText',
  }
</script>
